
.word_size_control{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}

.title{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  color: #000000;
}


.link{
  width: calc(100% - 40px);
  background: #FFFFFF;
  border: 1px solid #383838;
  border-radius: 6px;
  padding: 0px 12px;
  height: 32px;
  display: flex;
  align-items: center;
}

.comment{
  width: inherit;
}

.tooltip_text{
  width: 200px;
  background: #fff;
  opacity: 1;
  color: #000;
}

.link_icon{
  width: 24px;
  height: 24px;
  margin-left: 12px;
  cursor: pointer;
}

.link_text{
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 15px;
  color: #000000;
}

.copy_animation{
  animation-name: myAnimation;
  animation-duration: 1s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: 1;
}

.landing_item_tooltip{
  word-break: break-all;
  width: 200px;
  opacity: 1;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  border-radius: 10px;
  background: #fff;
  box-shadow: 0px 8px 12px 0px #00000026;
  color: #000;
}

.landing_item_buttons{
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@keyframes myAnimation {
  0% {
    background-color: #C4FECB;
  }

  100% {
    background-color: #fff;
  }
}


.edit_button{
  background: #383838;
  border-radius: 6px;
  font-family: 'Bebas Neue Cyrillic';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 92%;
  color: #fff;
  margin: 0px 6px;
}

.cursor_pointer{
  cursor: pointer;
}