.container{
  padding-top: 40px;
  overflow-x: hidden;
  width: 100%;
}

.container textarea{
  width: 100%;
  border-radius: 6px;
  border: 1px solid #383838;
  background: #FFF;
  height: 100px;
  display: flex;
  align-items: flex-start;
  resize: none;
  width: 100%;
  padding: 10px 12px;
}

.file_input_label{
  color: #2D8546;
  font-size: 14px;
  font-family: 'Inter';
  font-weight: 500;
  cursor: pointer;
}

.buttons{
  display: flex;
  margin-top: 26px;
  justify-content: space-between;
  align-items: center;
}

.file_button{
  display: flex;
}

.send_button {
  font-family: 'Bebas Neue Cyrillic';
  background: #383838;
  border-radius: 6px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 92%;
  color: #fff;
  padding: 0px 22px;
  height: 38px;
}



