.Notifications {
    position: fixed;
    right: 10px;
    top: 0;
    padding: 10px 0 0;
    height: auto;
    width: 400px;
    max-height: 100%;
    z-index: 1000;
}

.Notifications::-webkit-scrollbar {
    width: 0;
    height: 0;
}

@media (max-width: 768px) {
    .Notifications {
        width: 340px;
        right: 50%;
        margin-right: -170px;
    }
}
