
body{
  box-sizing: border-box;
  background-color: #FBFBFB;
  margin: 0;
}

a{
  text-decoration: none;
  box-sizing: border-box;
}
img{
  display: block;
}

.App{
  min-height: 100vh;
}

.modal-header,.modal-body,.modal-footer{
  border:none !important;
}

.react-tooltip {
  z-index: 2;
}