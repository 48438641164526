.container{
  display: none;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 92%;
  color: #CCCCCC;
  margin-top: 18px;
  margin-bottom: 40px;
  cursor: pointer;
}

.container img{
  margin-right: 8px;
}

@media (max-width: 1024px) {
  .container {
    display: flex;
  }
}