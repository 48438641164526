.view_container {
  width: 100%;
  min-height: 100vh;
  padding-bottom: 80px;
  background-color: #FFFFFF;
}

.bg_color {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  background-color: rgba(0, 0, 0, 0.5);
}

.view_header {
  padding: 60px 315px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  max-width: 1920px;
  margin: 0 auto;
  -webkit-box-flex: 1;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  position: relative;
  height: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-bottom: 80px;
}

.view_header .avatar {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  overflow: hidden;
  z-index: 1;
  min-width: 100px;
  min-height: 100px;
}

.view_nikname_text {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 23px;
  text-align: center;
  color: #FFFFFF;
  margin-top: 12px;
  z-index: 1;
}

.view_description_text {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  line-height: 56px;
  text-align: center;
  color: #FFFFFF;
  margin-top: 40px;
  width: 100%;
  word-break: break-word;
  z-index: 1;
}

.view_header .avatar img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.view_content {
  margin-top: 80px;
  display: flex;
  padding: 0px 80px;
  flex-wrap: wrap;
  max-width: 1920px;
  margin: 0 auto;
  -webkit-box-flex: 1;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  justify-content: center;
}

.view_item {
  width: 31%;
  margin: 0px 10px;
  margin-bottom: 80px;
  padding: 0px 8px;
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  flex-direction: column;
}

.view_product_image {
  width: 100%;
  height: 410px;
  margin: 0 auto;
  max-width: 100%;

  background-repeat: no-repeat;
  background-size: 100%;
  background-position: center;

  transition: all 0.3s ease;
}

.view_product_image:hover {
  background-size: 80%;
}

.view_product_image img {
  width: 100%;
  object-fit: contain;
  height: 100%;
}

.view_product_text {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* flex: auto; */
  justify-content: space-around;
}

.view_product_title_text {
  margin-top: 20px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 23px;
  text-align: center;
  color: #000000;
}

.view_product_description_text {
  margin-top: 4px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: #686868;
}

.word_size_control {
  /* white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%; */
}

.top_button {
  display: flex;
  justify-content: center;
}

.top_button button {
  color: #000;
  text-align: center;
  font-family: 'Roboto';
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  border-radius: 2px;
  border: 1px solid #CCC;
  height: 46px;
  padding: 0px 24px;
  background-color: transparent;
  margin-top: 80px;
  cursor: pointer;
  transition: transform ease 0.3s;
}


.top_button button:hover {
  border:none;
}



/*comment this from prod top button style */

.btn-style700 {
  transition: transform ease 0.3s;
}

.btn-style700::before,
.btn-style700::after {
  position: absolute;
  top: 0;
  left: 0;
  border-color: #348C4C;
  /* border-color: #dd6395; */
  border-radius: 5px;
  border-style: solid;
  transition: all 0.35s;
  content: "";
  z-index: 1;
}

.btn-style700::before {
  width: 0;
  height: 100%;
  border-width: 2px 0 2px 0;
}

.btn-style700::after {
  width: 100%;
  height: 0;
  border-width: 0 2px 0 2px;
}

.btn-style700:hover {
  border-color: #348C4C;
  color: #348C4C;
  transform: scale(1.2);
  
}

.btn-style700:hover::before {
  width: 100%;
  border-width: 2px 0 2px 0;
}

.btn-style700:hover::after {
  height: 100%;
  border-width: 0 2px 0 2px;
}

/*  */

/*use this from prod top button style */

/* 

.btn-style700 {
  position: relative;
  border-color: #979695;
  color: #979695;
  box-sizing: border-box;
}

.btn-style700::before,
.btn-style700::after {
  position: absolute;
  top: 0;
  left: 0;
  border-color: #348C4C;
  border-radius: 5px;
  border-style: solid;
  transition: all 0.35s;
  content: "";
  z-index: 1;
}

.btn-style700::before {
  width: 0;
  height: calc(100% - 4px);
  border-width: 2px 0 2px 0;
}

.btn-style700::after {
  width: calc(100% - 4px);
  height: 0;
  border-width: 0 2px 0 2px;
}

.btn-style700:hover {
  border-color: #348C4C;
  color: #348C4C;
  transform: scale(1.2);
}

.btn-style700:hover::before {
  width: 100%;
  height: calc(100% - 2px);
  border-width: 2px 0 2px 0;
}

.btn-style700:hover::after {
  height: 100%;
  width: calc(100% - 4px);
  border-width: 0 2px 0 2px;
}
*/


@media (max-width: 1920px) {
  .view_header {
    padding: 60px 315px;
  }

  .view_content {
    padding: 0px 45px;
  }

  .view_product_image {
    width: 350px;
    height: 350px;
  }
}

@media (max-width: 1400px) {

  .view_header {
    padding: 60px 70px;
  }

  .view_content {
    padding: 0px 45px;
  }

  .view_product_image {
    width: 350px;
    height: 350px;
  }
}

@media (max-width: 1200px) {
  .view_content {
    padding: 0px 45px;
  }

  .view_product_image {
    width: 350px;
    height: 350px;
  }

  .view_header {
    padding: 60px 70px;
    /* height: 407px; */
  }

  .view_description_text {
    font-size: 48px;
    line-height: normal;
  }
}

@media (max-width: 992px) {
  .view_header {
    padding: 60px 40px;
    height: 463px;
  }

  .view_content {
    padding: 0px 45px;
  }

  .view_product_image {
    width: 290px;
    height: 290px;
  }

  .view_content {
    justify-content: inherit;
  }

  .view_item {
    width: 30%;
  }

  .view_description_text {
    color: #FFF;
    text-align: center;
    font-family: 'Roboto';
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
}

@media (max-width: 768px) {
  .view_header {
    padding: 60px 40px;
    height: 421px;
  }

  .view_content {
    padding: 0px 45px;
  }

  .view_description_text {
    color: #FFF;
    text-align: center;
    font-family: 'Roboto';
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  .view_product_title_text {
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  .view_product_description_text {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .view_product_image {
    width: 210px;
    height: 210px;
  }

  .view_item {
    width: 28%;
  }

  .view_content {
    justify-content: inherit;
  }
}

@media (max-width: 578px) {
  .view_header {
    padding: 60px 32px;
    margin-bottom: 40px;
  }

  .view_content {
    padding: 0px 45px;
  }

  .view_description_text {
    font-size: 34px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  .view_product_title_text {
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  .view_product_description_text {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .view_product_image {
    width: 240px;
    height: 240px;
  }

  .view_item {
    width: 50%;
    margin: 0;
    margin-top: 0px;
    margin-bottom: 60px;
  }

  .view_content {
    justify-content: inherit;
  }
}


@media (max-width: 536px) {
  .view_header {
    padding: 60px 32px;
    margin-bottom: 40px;
    height: fit-content;
  }

  .view_content {
    padding: 0px 45px;
  }

  .view_description_text {
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  .view_product_title_text {
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  .view_product_description_text {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .view_product_image {
    width: 240px;
    height: 240px;
  }

  .view_item {
    width: 50%;
    margin: 0;
    margin-top: 0px;
    margin-bottom: 60px;
  }

  .view_content {
    justify-content: inherit;
  }
}

@media (max-width: 452px) {
  .view_header {
    padding: 60px 32px;
    margin-bottom: 40px;
    /* height: 407px; */
  }

  .view_description_text {
    color: #FFF;
    text-align: center;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
}


@media (max-width: 376px) {
  .view_header {
    padding: 60px 32px;
    margin-bottom: 40px;
  }

  .view_content {
    padding: 0px 45px;
  }

  .view_product_image {
    width: 140px;
    height: 140px;
  }

  .view_description_text {
    color: #FFF;
    text-align: center;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  .view_product_title_text {
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  .view_product_description_text {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .view_product_image {
    width: 315px;
    height: 315px;
  }

  .view_item {
    width: 100%;
    margin: 0;
    margin-top: 0px;
    margin-bottom: 60px;
  }

  .view_content {
    justify-content: inherit;
  }
}