.container{
  width: 100%;
  text-align: center;
  padding: 25px 0px;
}

.container span {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #2D8546;
}