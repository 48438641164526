.container{
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
      position: relative;
}

.selected_container{
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column-reverse;
  margin-bottom: 16px;
  width: fit-content;
  margin: 0 auto;
  position: relative;
}

.create_user_button{
  background: #FFFFFF;
  border: 2px solid #333333;
  border-radius: 6px;
  font-family: 'Bebas Neue Cyrillic';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 92%;
  color: #333333;
  padding: 10px 22px;
  display: flex;
  position: absolute;
  right: 0;
}

.create_user_button_mob{
  position: relative;
}

.create_user_button_mob_sel{
  position: relative;
}

.create_user_button img{
  margin-right: 12px;
}

.selected_create_user_button{
  background: #FFFFFF;
  border: 2px solid #333333;
  border-radius: 6px;
  font-family: 'Bebas Neue Cyrillic';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 92%;
  color: #333333;
  padding: 10px 22px;
  display: flex;
  width: 100%;
  justify-content: center;
  margin-bottom: 18px;
}




@media (max-width: 1024px) {
  .container {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    justify-content: space-between;
  }

  .create_user_button{
    width: fit-content;
    text-align: center;
    display: flex;
    justify-content: center;
    margin-bottom: 16px;
  }
}

@media (max-width: 768px) {
  .container {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    justify-content: space-between;
  }

  .create_user_button{
    text-align: center;
    display: flex;
    justify-content: center;
    margin-bottom: 24px;
  }
}