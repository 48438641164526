.container{
  padding: 20px 160px;
  padding-top: 40px;
  min-height: calc(100vh - 160px);
}

.title{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  color: #000000;
  margin-bottom: 44px;
}



@media (max-width: 1024px) {
  .container {
    padding: 12px;
  }
}