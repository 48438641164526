.container{
  background: #FFFFFF;
  box-shadow: 0px 3px 9px rgba(0, 0, 0, 0.15);
  display: flex;
  align-items: center;
  position: relative;
  z-index: 2;
}

.wrapper{
  padding: 0px 160px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 1920px;
  margin: 0 auto;
  height: 80px;
}

.logo{
  margin-right: 60px;
}

.left_content{
  display: flex;
  align-items: center;
}


.header_link:first-child{
  margin-right: 38px;
}

.links{
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}

.header_link {
  font-family: 'Bebas Neue Cyrillic';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  color: #333333;
}

.right_content{
  display: flex;
  align-items: center;
}

.button{
  padding: 6px 14px;
  background: #FFFFFF;
  border: 1px solid #222222;
  border-radius: 8px;
  margin-left: 20px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  color: #222222;
}

.burger_menu{
  display: none;
  cursor: pointer;
}

.email{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #A7A7A7;
}

.logout {
  cursor: pointer;
  margin-left: 10px
}

.your_manager_text{
  color: #A7A7A7;
  text-align: center;
  font-size: 14px;
  font-family: 'Inter';
  font-weight: 600;
  line-height: 24px;
}

.your_manager_email{
  color: #2D8546;
  text-align: center;
  font-size: 14px;
  font-family: 'Inter';
  font-weight: 600;
  line-height: 24px;
}

@media (max-width: 1112px) {
  .wrapper {
    padding: 0px 120px ;
  }
}


@media (max-width: 1074px) {
  .wrapper {
    padding: 0px 120px ;
  }
}

@media (max-width: 1032px) {
  .wrapper {
    padding: 0px 110px;
  }
}

@media (max-width: 1024px) {
  .wrapper {
    padding:0px 12px;
  }

  .left_content, .right_content {
    display: none;
  }

  .burger_menu {
    display: inline-block;
  }
  .links{
    justify-content: flex-end;
  }
}