.first_row_container{
  display: flex;
  align-items: center;
}

.second_row_container{
  margin: 16px 0px;
  padding: 0px;
}

.second_row_container_content{
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0px;
}

.second_row_container_content div{
  width: 100%;
}

.second_row_container_content div input{
  width: 100%;
  padding: 8px 10px;
  font-size: 13px;
  background: #FFFFFF;
  border: 1px solid #383838;
  border-radius: 6px;
}

.word_size_control {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}

.title {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  color: #000000;
}


.link {
  width: calc(100% - 40px);
  background: #FFFFFF;
  border: 1px solid #383838;
  border-radius: 6px;
  padding: 0px 12px;
  height: 32px;
  display: flex;
  align-items: center;
}

.link_icon {
  width: 24px;
  height: 24px;
  margin-left: 12px;
  cursor: pointer;
}

.link_text {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 15px;
  color: #000000;
}

.copy_animation {
  animation-name: myAnimation;
  animation-duration: 1s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: 1;
}



@keyframes myAnimation {
  0% {
    background-color: #C4FECB;
  }

  100% {
    background-color: #fff;
  }
}


.edit_button {
  background: #383838;
  border-radius: 6px;
  font-family: 'Bebas Neue Cyrillic';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 92%;
  color: #fff;
  margin-right: 25px;
}

.view_button {
  padding: 0px 10px;
  background-color: #fff;
  background: #FFFFFF;
  border: 2px solid #333333;
  border-radius: 6px;
  font-family: 'Bebas Neue Cyrillic';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 92%;
  color: #333333;
  position: relative;
  overflow: hidden;
  height: 38px;
}

.reject_button {
  border: 2px solid #B23333;
  font-family: 'Bebas Neue Cyrillic';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 92%;
  color: #B23333;
}

.view_button img {
  margin: 0;
}

.lunch_button {
  padding: 0px 25px;
  background: #383838;
  border: 2px solid #333333;
  border-radius: 6px;
  font-family: 'Bebas Neue Cyrillic';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 92%;
  position: relative;
  overflow: hidden;
  height: 38px;
  color: #fff;
}

.content_center{
  display: flex;
  justify-content: center;
}

.content_flex_end{
  display: flex;
  justify-content: flex-end;
}