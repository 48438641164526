.container{
  min-width: 100%;
  position: relative;
  min-height: 86vh;
}

.header_row{
  border-bottom: 1px solid #D9D9D9;
  padding-bottom: 8px;
  width: 100%;
}

.content_row{
  padding-top: 10px;
  padding-bottom: 12px;
  align-items: center;
  width: 100%;
  border-bottom: 1px solid #D9D9D9;
}