.container {
  padding: 20px 0px;
  padding-top: 40px;
  overflow-x: hidden;
  min-height: calc(100vh - 160px);
  width: 54%;
  margin: 0 auto;
  max-width: 1920px;
  margin: 0 auto;
  -webkit-box-flex: 1;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.title {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  color: #000000;
  margin-bottom: 26px;
}


@media (max-width: 1024px) {
  .container {
    padding: 12px;
  }
}


@media (max-width: 992px) {
  .container {
    padding: 12px;
    width: 100%;
  }
}