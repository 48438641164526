.approve_container{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #2D8546;
  background: #C4FECB;
  border-radius: 10px;
  width: fit-content;
  padding: 2px 6px;
}

.not_approve_container{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #B23333;
  background: #FFD0D0;
  border-radius: 10px;
  width: fit-content;
  padding: 2px 6px;
}

.moderation_container{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #C19933;
  background: #FFEDBD;
  border-radius: 10px;
  width: fit-content;
  padding: 2px 6px;
}

.in_archive{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #808080;
  background: #E8E8E8;
  border-radius: 10px;
  width: fit-content;
  padding: 2px 6px;
}

.draft{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #165380;
  background: #67B3EB;
  border-radius: 10px;
  width: fit-content;
  padding: 2px 6px;
}