.container{
  height: 100%;
  background-color: rgba(255,255,255,0);
  position: absolute;
  top: 64px;
  right: 0;
  z-index: 1;
  width: 100%;
}

.navbar_mobile {
  background-color: #fff;
  height: fit-content;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding-right: 40px;
  padding-top: 30px;
  padding-bottom: 30px;
  box-shadow: 0px 3px 9px rgba(0, 0, 0, 0.15);
  display: none;
}

.email {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #A7A7A7;
  margin-top: 6px;
}

.logout {
  cursor: pointer;
  margin-left: 10px
}

.logoutIcon{
  margin-left: 8px;
}

.button {
  padding: 6px 14px;
  background: #FFFFFF;
  border: 1px solid #222222;
  border-radius: 8px;
  margin-left: 20px;
}

.your_manager_text {
  color: #A7A7A7;
  text-align: center;
  font-size: 14px;
  font-family: 'Inter';
  font-weight: 600;
  line-height: 24px;
}

.your_manager_email {
  color: #2D8546;
  text-align: center;
  font-size: 14px;
  font-family: 'Inter';
  font-weight: 600;
  line-height: 24px;
}

@media (max-width: 1024px) {

  .navbar_mobile {
    display: flex;
  }
  
  .email {
    margin-bottom: 30px;
  }

  .logout {
    display: flex;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #A7A7A7;
    margin-bottom: 30px;
  }

  .affise_button {
    width: 100%;
    display: flex;
    justify-content: center;
  }
}