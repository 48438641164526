.container{
  background: #C4FECB;
  height: 106px;
}

.wrapper {
  padding: 12px 160px;
  max-width: 1920px;
  margin: 0 auto;
}

.email{
  display: flex;
  align-items: center;
}
.email img{
  width: 18px;
  height: 18px;
  margin-right: 6px;
}

.question{
  color: #000;
  font-size: 14px;
  font-family: 'Inter';
  font-weight: 600;
  line-height: 24px;
}

.email_text{
  color: #2D8546;
  font-size: 14px;
  font-family: 'Inter';
  font-weight: 600;
  line-height: 24px;
}

@media (max-width: 992px) {
  .wrapper {
    padding: 12px 0px;
    padding-left: 16px;
  }
}