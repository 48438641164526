/* STYLE B */

.checkbox.style_b {
    display: flex;
    position: relative;
    padding-left: 30px;
    cursor: pointer;
    -webkit-user-select: none;
    user-select: none;
    align-items: center;
    margin: 10px 0px;
}

.checkbox.style_b input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

.checkbox.style_b input:checked~.checkbox__checkmark {
    background-color: #fff;
}

.checkbox.style_b input:checked~.checkbox__checkmark:after {
    opacity: 1;
}

.checkbox.style_b .checkbox__checkmark {
    position: absolute;
    left: 0;
    height: 18px;
    width: 18px;
    background-color: #fff;
    border: 2px solid #31824E;
    transition: background-color 0.25s ease;
    border-radius: 4px;
}

.checkbox.style_b .checkbox__checkmark:after {
    content: "";
    position: absolute;
    left: 5px;
    top: 1px;
    width: 5px;
    height: 10px;
    border: solid #31824E;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
    opacity: 0;
    transition: opacity 0.25s ease;
}

.checkbox.style_b .checkbox__body {
    color: #000;
    font-family: Roboto;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}