
.first_content_wrapper{
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}


.first_content{
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 80%;
}

.title{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  color: #000000;
  margin-right: 8px;
}

.comment_icon{
  margin-left: 8px;
}

.buttons{
  display: flex;
  justify-content: center;
  align-items: center;
}

.edit_button{
  margin-top: -4px;
  margin-right: 8px;
}

.word_size_control {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.show_button{
  margin-top: 0px;
}

.link {
  width: calc(100% - 40px);
  background: #FFFFFF;
  border: 1px solid #383838;
  border-radius: 6px;
  padding: 0px 12px;
  height: 32px;
  display: flex;
  align-items: center;
}

.link_icon {
  width: 24px;
  height: 24px;
  margin-left: 12px;
  cursor: pointer;
}

.link_text {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 15px;
  color: #000000;
}

.copy_animation {
  animation-name: myAnimation;
  animation-duration: 1s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: 1;
}

.landing_mobile_item_tooltip{
  width: 200px;
  word-break: break-all;
  opacity: 1;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  border-radius: 10px;
  background: #fff;
  box-shadow: 0px 8px 12px 0px #00000026;
  color: #000;
}

.buttons img{
  cursor: pointer;
}

@keyframes myAnimation {
  0% {
    background-color: #C4FECB;
  }

  100% {
    background-color: #fff;
  }
}


.second_content_wrapper{
  margin-top: 4px;
}

.link_title{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #808080;
}

.second_content{
  display: flex;
  align-items: center;
  justify-content: space-around;
}

@media (max-width: 414px) {
 .first_content_wrapper{
    flex-direction: column-reverse;
 }
 .first_content{
  width:100%;
  display: flex;
  justify-content: space-between;
 }
 .buttons{
  width: 100%;
  margin-bottom: 15px;
 }
 .buttons img{
  margin-right: 12px;
  margin-left: 12px;
 }
 .edit_button {
  margin-right: 8px;
 }
}