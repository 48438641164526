.header_link:first-child {
  margin-right: 38px;
}

.header_link {
  font-family: 'Bebas Neue Cyrillic';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  color: #333333;
}

@media (max-width: 1024px) {
  .header_link:first-child {
    margin-right: 0px;
  }
  .header_link {
    margin-bottom: 30px;
  }
}