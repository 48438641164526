.empty_text {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 19px;
  text-align: center;
  color: #838383;
  display: flex;
  justify-content: center;
  margin-top: 25px;
  display: none;
}