.container {
  min-width: 100%;
  padding: 0px;
  position: relative;
  margin-bottom: 26px;
}

.col {
  padding: 0px 2px;
}

.col .bg {
  width: 100%;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-size: 100% 32px;
  background-repeat: no-repeat;
}

.status_text {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 32px;
  color: #2D8546;
}

.comment_tooltip_icon{
  width: fit-content;
  height: fit-content;
  cursor: pointer;
  position: absolute;
  top:50%;
  right:-30px;
  transform: translate(-50%,-50%);
}

.comment_tooltip{
  width: 200px;
  opacity: 1;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  border-radius: 10px;
  background: #fff;
  box-shadow: 0px 8px 12px 0px #00000026;
  color: #000;
}



@media (max-width: 1024px) {
  .container {
    padding-left: 8px;
    padding-right: 8px;
  }
}


@media (max-width: 639px) {
  .col .bg span {
    font-size: 10px;
    line-height: 1;
    text-align: center;
    width: 80px;
  }
  .col .bg {
    height: 36px;
    background-size: 100% 36px;
  }
  .comment_tooltip {
    right: -20%;
  }
}

@media (max-width: 414px) {
  .col .bg {
    font-size: 8px;
  }
}