.InputUi {
    display: flex;
    flex-direction: column;
    position: relative;
}

.InputUi label {
    font-family: "Bebas Neue Pro";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: #383838;
    letter-spacing: -0.01em;
    margin-bottom: 5px;

}

.InputUi span {
    color: #A94536;
}

.InputUi input {
    padding: 10px;
    width: 100%;
    background: #FFFFFF;
    border: 1px solid #383838;
    border-radius: 6px;
    box-sizing: border-box;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 15px;
}

.Authorization {
    text-transform: uppercase;
}

.InputUi div {
    font-family: Inter;
    font-size: 12px;
    text-transform: none;
    color: red;
    margin-top: 6px;
    position: absolute;
    bottom: -18px;
    background: #FFFFFF;
    padding: 0 10px;
    box-shadow: 0px 8px 12px rgba(0, 0, 0, 0.15);
    border-radius: 8px;
}