.container {
  padding: 20px 160px;
  padding-top: 40px;
  max-width: 1920px;
    margin: 0 auto;
    -webkit-box-flex: 1;
    -ms-flex: 1 0 auto;
    flex: 1 0 auto;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

@media (max-width: 1024px) {
  .container {
    padding: 12px;
  }
}