@font-face {
  font-family: 'Bebas Neue Cyrillic';
  src: url('../fonts/bebasneuecyrillic.ttf');
  font-weight: 500;
}

@font-face {
  font-family: 'Roboto';
  src: url('../fonts/Roboto-Regular.ttf');
  font-weight: 500;
}


@font-face {
  font-family: 'Bebas Neue Pro Middle';
  src: url('../fonts/BebasNeuePro-MiddleItalic.eot');
  src: local('Bebas Neue Pro Middle Italic'), local('BebasNeuePro-MiddleItalic'),
    url('../fonts/BebasNeuePro-MiddleItalic.eot?#iefix') format('embedded-opentype'),
    url('../fonts/BebasNeuePro-MiddleItalic.woff2') format('woff2'),
    url('../fonts/BebasNeuePro-MiddleItalic.woff') format('woff'),
    url('../fonts/BebasNeuePro-MiddleItalic.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Bebas Neue Pro';
  src: url('../fonts/BebasNeuePro-BoldItalic.eot');
  src: local('Bebas Neue Pro Bold Italic'), local('BebasNeuePro-BoldItalic'),
    url('../fonts/BebasNeuePro-BoldItalic.eot?#iefix') format('embedded-opentype'),
    url('../fonts/BebasNeuePro-BoldItalic.woff2') format('woff2'),
    url('../fonts/BebasNeuePro-BoldItalic.woff') format('woff'),
    url('../fonts/BebasNeuePro-BoldItalic.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Bebas Neue Pro Expanded';
  src: url('../fonts/BebasNeuePro-ExpandedRegular.eot');
  src: local('Bebas Neue Pro Expanded Regular'), local('BebasNeuePro-ExpandedRegular'),
    url('../fonts/BebasNeuePro-ExpandedRegular.eot?#iefix') format('embedded-opentype'),
    url('../fonts/BebasNeuePro-ExpandedRegular.woff2') format('woff2'),
    url('../fonts/BebasNeuePro-ExpandedRegular.woff') format('woff'),
    url('../fonts/BebasNeuePro-ExpandedRegular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Bebas Neue Pro Expanded';
  src: url('../fonts/BebasNeuePro-ExpandedMediumIt.eot');
  src: local('Bebas Neue Pro Expanded Medium Italic'), local('BebasNeuePro-ExpandedMediumIt'),
    url('../fonts/BebasNeuePro-ExpandedMediumIt.eot?#iefix') format('embedded-opentype'),
    url('../fonts/BebasNeuePro-ExpandedMediumIt.woff2') format('woff2'),
    url('../fonts/BebasNeuePro-ExpandedMediumIt.woff') format('woff'),
    url('../fonts/BebasNeuePro-ExpandedMediumIt.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Bebas Neue Pro Expanded';
  src: url('../fonts/BebasNeuePro-ExpandedItalic.eot');
  src: local('Bebas Neue Pro Expanded Italic'), local('BebasNeuePro-ExpandedItalic'),
    url('../fonts/BebasNeuePro-ExpandedItalic.eot?#iefix') format('embedded-opentype'),
    url('../fonts/BebasNeuePro-ExpandedItalic.woff2') format('woff2'),
    url('../fonts/BebasNeuePro-ExpandedItalic.woff') format('woff'),
    url('../fonts/BebasNeuePro-ExpandedItalic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Bebas Neue Pro Expanded Book';
  src: url('../fonts/BebasNeuePro-ExpandedBook.eot');
  src: local('Bebas Neue Pro Expanded Book'), local('BebasNeuePro-ExpandedBook'),
    url('../fonts/BebasNeuePro-ExpandedBook.eot?#iefix') format('embedded-opentype'),
    url('../fonts/BebasNeuePro-ExpandedBook.woff2') format('woff2'),
    url('../fonts/BebasNeuePro-ExpandedBook.woff') format('woff'),
    url('../fonts/BebasNeuePro-ExpandedBook.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Bebas Neue Pro SemiExp';
  src: url('../fonts/BebasNeuePro-SemiExpThinItalic.eot');
  src: local('Bebas Neue Pro SemiExpanded Thin Italic'), local('BebasNeuePro-SemiExpThinItalic'),
    url('../fonts/BebasNeuePro-SemiExpThinItalic.eot?#iefix') format('embedded-opentype'),
    url('../fonts/BebasNeuePro-SemiExpThinItalic.woff2') format('woff2'),
    url('../fonts/BebasNeuePro-SemiExpThinItalic.woff') format('woff'),
    url('../fonts/BebasNeuePro-SemiExpThinItalic.ttf') format('truetype');
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: 'Bebas Neue Pro Expanded';
  src: url('../fonts/BebasNeuePro-ExpandedThinItalic.eot');
  src: local('Bebas Neue Pro Expanded Thin Italic'), local('BebasNeuePro-ExpandedThinItalic'),
    url('../fonts/BebasNeuePro-ExpandedThinItalic.eot?#iefix') format('embedded-opentype'),
    url('../fonts/BebasNeuePro-ExpandedThinItalic.woff2') format('woff2'),
    url('../fonts/BebasNeuePro-ExpandedThinItalic.woff') format('woff'),
    url('../fonts/BebasNeuePro-ExpandedThinItalic.ttf') format('truetype');
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: 'Bebas Neue Pro Expanded Book';
  src: url('../fonts/BebasNeuePro-ExpandedBookItalic.eot');
  src: local('Bebas Neue Pro Expanded Book Italic'), local('BebasNeuePro-ExpandedBookItalic'),
    url('../fonts/BebasNeuePro-ExpandedBookItalic.eot?#iefix') format('embedded-opentype'),
    url('../fonts/BebasNeuePro-ExpandedBookItalic.woff2') format('woff2'),
    url('../fonts/BebasNeuePro-ExpandedBookItalic.woff') format('woff'),
    url('../fonts/BebasNeuePro-ExpandedBookItalic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Bebas Neue Pro SemiExp';
  src: url('../fonts/BebasNeuePro-SemiExpandedThin.eot');
  src: local('Bebas Neue Pro SemiExpanded Thin'), local('BebasNeuePro-SemiExpandedThin'),
    url('../fonts/BebasNeuePro-SemiExpandedThin.eot?#iefix') format('embedded-opentype'),
    url('../fonts/BebasNeuePro-SemiExpandedThin.woff2') format('woff2'),
    url('../fonts/BebasNeuePro-SemiExpandedThin.woff') format('woff'),
    url('../fonts/BebasNeuePro-SemiExpandedThin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Bebas Neue Pro Expanded';
  src: url('../fonts/BebasNeuePro-ExpandedMedium.eot');
  src: local('Bebas Neue Pro Expanded Medium'), local('BebasNeuePro-ExpandedMedium'),
    url('../fonts/BebasNeuePro-ExpandedMedium.eot?#iefix') format('embedded-opentype'),
    url('../fonts/BebasNeuePro-ExpandedMedium.woff2') format('woff2'),
    url('../fonts/BebasNeuePro-ExpandedMedium.woff') format('woff'),
    url('../fonts/BebasNeuePro-ExpandedMedium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Bebas Neue Pro SemiExp Book';
  src: url('../fonts/BebasNeuePro-SemiExpBook.eot');
  src: local('Bebas Neue Pro SemiExpanded Book'), local('BebasNeuePro-SemiExpBook'),
    url('../fonts/BebasNeuePro-SemiExpBook.eot?#iefix') format('embedded-opentype'),
    url('../fonts/BebasNeuePro-SemiExpBook.woff2') format('woff2'),
    url('../fonts/BebasNeuePro-SemiExpBook.woff') format('woff'),
    url('../fonts/BebasNeuePro-SemiExpBook.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Bebas Neue Pro SemiExp';
  src: url('../fonts/BebasNeuePro-SemiExpLightItalic.eot');
  src: local('Bebas Neue Pro SemiExpanded Light Italic'), local('BebasNeuePro-SemiExpLightItalic'),
    url('../fonts/BebasNeuePro-SemiExpLightItalic.eot?#iefix') format('embedded-opentype'),
    url('../fonts/BebasNeuePro-SemiExpLightItalic.woff2') format('woff2'),
    url('../fonts/BebasNeuePro-SemiExpLightItalic.woff') format('woff'),
    url('../fonts/BebasNeuePro-SemiExpLightItalic.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Bebas Neue Pro SemiExp';
  src: url('../fonts/BebasNeuePro-SemiExpRegular.eot');
  src: local('Bebas Neue Pro SemiExpanded Regular'), local('BebasNeuePro-SemiExpRegular'),
    url('../fonts/BebasNeuePro-SemiExpRegular.eot?#iefix') format('embedded-opentype'),
    url('../fonts/BebasNeuePro-SemiExpRegular.woff2') format('woff2'),
    url('../fonts/BebasNeuePro-SemiExpRegular.woff') format('woff'),
    url('../fonts/BebasNeuePro-SemiExpRegular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Bebas Neue Pro';
  src: url('../fonts/BebasNeuePro-Light.eot');
  src: local('Bebas Neue Pro Light'), local('BebasNeuePro-Light'),
    url('../fonts/BebasNeuePro-Light.eot?#iefix') format('embedded-opentype'),
    url('../fonts/BebasNeuePro-Light.woff2') format('woff2'),
    url('../fonts/BebasNeuePro-Light.woff') format('woff'),
    url('../fonts/BebasNeuePro-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Bebas Neue Pro Expanded';
  src: url('../fonts/BebasNeuePro-ExpandedBoldItalic.eot');
  src: local('Bebas Neue Pro Expanded Bold Italic'), local('BebasNeuePro-ExpandedBoldItalic'),
    url('../fonts/BebasNeuePro-ExpandedBoldItalic.eot?#iefix') format('embedded-opentype'),
    url('../fonts/BebasNeuePro-ExpandedBoldItalic.woff2') format('woff2'),
    url('../fonts/BebasNeuePro-ExpandedBoldItalic.woff') format('woff'),
    url('../fonts/BebasNeuePro-ExpandedBoldItalic.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Bebas Neue Pro';
  src: url('../fonts/BebasNeuePro-LightItalic.eot');
  src: local('Bebas Neue Pro Light Italic'), local('BebasNeuePro-LightItalic'),
    url('../fonts/BebasNeuePro-LightItalic.eot?#iefix') format('embedded-opentype'),
    url('../fonts/BebasNeuePro-LightItalic.woff2') format('woff2'),
    url('../fonts/BebasNeuePro-LightItalic.woff') format('woff'),
    url('../fonts/BebasNeuePro-LightItalic.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Bebas Neue Pro';
  src: url('../fonts/BebasNeuePro-Thin.eot');
  src: local('Bebas Neue Pro Thin'), local('BebasNeuePro-Thin'),
    url('../fonts/BebasNeuePro-Thin.eot?#iefix') format('embedded-opentype'),
    url('../fonts/BebasNeuePro-Thin.woff2') format('woff2'),
    url('../fonts/BebasNeuePro-Thin.woff') format('woff'),
    url('../fonts/BebasNeuePro-Thin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Bebas Neue Pro Expanded XBold';
  src: url('../fonts/BebasNeueProExpandedExtraBoldIt.eot');
  src: local('Bebas Neue Pro Expanded ExtraBold Italic'), local('BebasNeueProExpandedExtraBoldIt'),
    url('../fonts/BebasNeueProExpandedExtraBoldIt.eot?#iefix') format('embedded-opentype'),
    url('../fonts/BebasNeueProExpandedExtraBoldIt.woff2') format('woff2'),
    url('../fonts/BebasNeueProExpandedExtraBoldIt.woff') format('woff'),
    url('../fonts/BebasNeueProExpandedExtraBoldIt.ttf') format('truetype');
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: 'Bebas Neue Pro Expanded';
  src: url('../fonts/BebasNeuePro-ExpandedBold.eot');
  src: local('Bebas Neue Pro Expanded Bold'), local('BebasNeuePro-ExpandedBold'),
    url('../fonts/BebasNeuePro-ExpandedBold.eot?#iefix') format('embedded-opentype'),
    url('../fonts/BebasNeuePro-ExpandedBold.woff2') format('woff2'),
    url('../fonts/BebasNeuePro-ExpandedBold.woff') format('woff'),
    url('../fonts/BebasNeuePro-ExpandedBold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Bebas Neue Pro SemiExp Book';
  src: url('../fonts/BebasNeuePro-SemiExpBookItalic.eot');
  src: local('Bebas Neue Pro SemiExpanded Book Italic'), local('BebasNeuePro-SemiExpBookItalic'),
    url('../fonts/BebasNeuePro-SemiExpBookItalic.eot?#iefix') format('embedded-opentype'),
    url('../fonts/BebasNeuePro-SemiExpBookItalic.woff2') format('woff2'),
    url('../fonts/BebasNeuePro-SemiExpBookItalic.woff') format('woff'),
    url('../fonts/BebasNeuePro-SemiExpBookItalic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Bebas Neue Pro';
  src: url('../fonts/BebasNeuePro-ThinItalic.eot');
  src: local('Bebas Neue Pro Thin Italic'), local('BebasNeuePro-ThinItalic'),
    url('../fonts/BebasNeuePro-ThinItalic.eot?#iefix') format('embedded-opentype'),
    url('../fonts/BebasNeuePro-ThinItalic.woff2') format('woff2'),
    url('../fonts/BebasNeuePro-ThinItalic.woff') format('woff'),
    url('../fonts/BebasNeuePro-ThinItalic.ttf') format('truetype');
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: 'Bebas Neue Pro Book';
  src: url('../fonts/BebasNeuePro-Book.eot');
  src: local('Bebas Neue Pro Book'), local('BebasNeuePro-Book'),
    url('../fonts/BebasNeuePro-Book.eot?#iefix') format('embedded-opentype'),
    url('../fonts/BebasNeuePro-Book.woff2') format('woff2'),
    url('../fonts/BebasNeuePro-Book.woff') format('woff'),
    url('../fonts/BebasNeuePro-Book.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Bebas Neue Pro Book';
  src: url('../fonts/BebasNeuePro-BookItalic.eot');
  src: local('Bebas Neue Pro Book Italic'), local('BebasNeuePro-BookItalic'),
    url('../fonts/BebasNeuePro-BookItalic.eot?#iefix') format('embedded-opentype'),
    url('../fonts/BebasNeuePro-BookItalic.woff2') format('woff2'),
    url('../fonts/BebasNeuePro-BookItalic.woff') format('woff'),
    url('../fonts/BebasNeuePro-BookItalic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Bebas Neue Pro SemiExp';
  src: url('../fonts/BebasNeuePro-SemiExpItalic.eot');
  src: local('Bebas Neue Pro SemiExpanded Italic'), local('BebasNeuePro-SemiExpItalic'),
    url('../fonts/BebasNeuePro-SemiExpItalic.eot?#iefix') format('embedded-opentype'),
    url('../fonts/BebasNeuePro-SemiExpItalic.woff2') format('woff2'),
    url('../fonts/BebasNeuePro-SemiExpItalic.woff') format('woff'),
    url('../fonts/BebasNeuePro-SemiExpItalic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Bebas Neue Pro Expanded';
  src: url('../fonts/BebasNeuePro-ExpandedLight.eot');
  src: local('Bebas Neue Pro Expanded Light'), local('BebasNeuePro-ExpandedLight'),
    url('../fonts/BebasNeuePro-ExpandedLight.eot?#iefix') format('embedded-opentype'),
    url('../fonts/BebasNeuePro-ExpandedLight.woff2') format('woff2'),
    url('../fonts/BebasNeuePro-ExpandedLight.woff') format('woff'),
    url('../fonts/BebasNeuePro-ExpandedLight.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Bebas Neue Pro Expanded';
  src: url('../fonts/BebasNeuePro-ExpandedThin.eot');
  src: local('Bebas Neue Pro Expanded Thin'), local('BebasNeuePro-ExpandedThin'),
    url('../fonts/BebasNeuePro-ExpandedThin.eot?#iefix') format('embedded-opentype'),
    url('../fonts/BebasNeuePro-ExpandedThin.woff2') format('woff2'),
    url('../fonts/BebasNeuePro-ExpandedThin.woff') format('woff'),
    url('../fonts/BebasNeuePro-ExpandedThin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Bebas Neue Pro SemiExp XBold';
  src: url('../fonts/BebasNeuePro-SemiExpXBold.eot');
  src: local('Bebas Neue Pro SemiExpanded ExtraBold'), local('BebasNeuePro-SemiExpXBold'),
    url('../fonts/BebasNeuePro-SemiExpXBold.eot?#iefix') format('embedded-opentype'),
    url('../fonts/BebasNeuePro-SemiExpXBold.woff2') format('woff2'),
    url('../fonts/BebasNeuePro-SemiExpXBold.woff') format('woff'),
    url('../fonts/BebasNeuePro-SemiExpXBold.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Bebas Neue Pro Middle';
  src: url('../fonts/BebasNeuePro-Middle.eot');
  src: local('Bebas Neue Pro Middle'), local('BebasNeuePro-Middle'),
    url('../fonts/BebasNeuePro-Middle.eot?#iefix') format('embedded-opentype'),
    url('../fonts/BebasNeuePro-Middle.woff2') format('woff2'),
    url('../fonts/BebasNeuePro-Middle.woff') format('woff'),
    url('../fonts/BebasNeuePro-Middle.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Bebas Neue Pro';
  src: url('../fonts/BebasNeuePro-Bold.eot');
  src: local('Bebas Neue Pro Bold'), local('BebasNeuePro-Bold'),
    url('../fonts/BebasNeuePro-Bold.eot?#iefix') format('embedded-opentype'),
    url('../fonts/BebasNeuePro-Bold.woff2') format('woff2'),
    url('../fonts/BebasNeuePro-Bold.woff') format('woff'),
    url('../fonts/BebasNeuePro-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Bebas Neue Pro SemiExp';
  src: url('../fonts/BebasNeuePro-SemiExpBold.eot');
  src: local('Bebas Neue Pro SemiExpanded Bold'), local('BebasNeuePro-SemiExpBold'),
    url('../fonts/BebasNeuePro-SemiExpBold.eot?#iefix') format('embedded-opentype'),
    url('../fonts/BebasNeuePro-SemiExpBold.woff2') format('woff2'),
    url('../fonts/BebasNeuePro-SemiExpBold.woff') format('woff'),
    url('../fonts/BebasNeuePro-SemiExpBold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Bebas Neue Pro';
  src: url('../fonts/BebasNeuePro-Italic.eot');
  src: local('Bebas Neue Pro Italic'), local('BebasNeuePro-Italic'),
    url('../fonts/BebasNeuePro-Italic.eot?#iefix') format('embedded-opentype'),
    url('../fonts/BebasNeuePro-Italic.woff2') format('woff2'),
    url('../fonts/BebasNeuePro-Italic.woff') format('woff'),
    url('../fonts/BebasNeuePro-Italic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Bebas Neue Pro Expanded XBold';
  src: url('../fonts/BebasNeuePro-ExpandedExtraBold.eot');
  src: local('Bebas Neue Pro Expanded ExtraBold'), local('BebasNeuePro-ExpandedExtraBold'),
    url('../fonts/BebasNeuePro-ExpandedExtraBold.eot?#iefix') format('embedded-opentype'),
    url('../fonts/BebasNeuePro-ExpandedExtraBold.woff2') format('woff2'),
    url('../fonts/BebasNeuePro-ExpandedExtraBold.woff') format('woff'),
    url('../fonts/BebasNeuePro-ExpandedExtraBold.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Bebas Neue Pro SemiExp';
  src: url('../fonts/BebasNeuePro-SemiExpLight.eot');
  src: local('Bebas Neue Pro SemiExpanded Light'), local('BebasNeuePro-SemiExpLight'),
    url('../fonts/BebasNeuePro-SemiExpLight.eot?#iefix') format('embedded-opentype'),
    url('../fonts/BebasNeuePro-SemiExpLight.woff2') format('woff2'),
    url('../fonts/BebasNeuePro-SemiExpLight.woff') format('woff'),
    url('../fonts/BebasNeuePro-SemiExpLight.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Bebas Neue Pro';
  src: url('../fonts/BebasNeuePro-Regular.eot');
  src: local('Bebas Neue Pro Regular'), local('BebasNeuePro-Regular'),
    url('../fonts/BebasNeuePro-Regular.eot?#iefix') format('embedded-opentype'),
    url('../fonts/BebasNeuePro-Regular.woff2') format('woff2'),
    url('../fonts/BebasNeuePro-Regular.woff') format('woff'),
    url('../fonts/BebasNeuePro-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Bebas Neue Pro SemiExp Middle';
  src: url('../fonts/BebasNeuePro-SemiExpMiddle.eot');
  src: local('Bebas Neue Pro SemiExpanded Middle'), local('BebasNeuePro-SemiExpMiddle'),
    url('../fonts/BebasNeuePro-SemiExpMiddle.eot?#iefix') format('embedded-opentype'),
    url('../fonts/BebasNeuePro-SemiExpMiddle.woff2') format('woff2'),
    url('../fonts/BebasNeuePro-SemiExpMiddle.woff') format('woff'),
    url('../fonts/BebasNeuePro-SemiExpMiddle.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Bebas Neue Pro SemiExp XBold';
  src: url('../fonts/BebasNeuePro-SemiExpXBoldItalic.eot');
  src: local('Bebas Neue Pro SemiExpanded ExtraBold Italic'), local('BebasNeuePro-SemiExpXBoldItalic'),
    url('../fonts/BebasNeuePro-SemiExpXBoldItalic.eot?#iefix') format('embedded-opentype'),
    url('../fonts/BebasNeuePro-SemiExpXBoldItalic.woff2') format('woff2'),
    url('../fonts/BebasNeuePro-SemiExpXBoldItalic.woff') format('woff'),
    url('../fonts/BebasNeuePro-SemiExpXBoldItalic.ttf') format('truetype');
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: 'Bebas Neue Pro Expanded';
  src: url('../fonts/BebasNeuePro-ExpandedLightIt.eot');
  src: local('Bebas Neue Pro Expanded Light Italic'), local('BebasNeuePro-ExpandedLightIt'),
    url('../fonts/BebasNeuePro-ExpandedLightIt.eot?#iefix') format('embedded-opentype'),
    url('../fonts/BebasNeuePro-ExpandedLightIt.woff2') format('woff2'),
    url('../fonts/BebasNeuePro-ExpandedLightIt.woff') format('woff'),
    url('../fonts/BebasNeuePro-ExpandedLightIt.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Bebas Neue Pro SemiExp Middle';
  src: url('../fonts/BebasNeuePro-SemiExpMiddleIt.eot');
  src: local('Bebas Neue Pro SemiExpanded Middle Italic'), local('BebasNeuePro-SemiExpMiddleIt'),
    url('../fonts/BebasNeuePro-SemiExpMiddleIt.eot?#iefix') format('embedded-opentype'),
    url('../fonts/BebasNeuePro-SemiExpMiddleIt.woff2') format('woff2'),
    url('../fonts/BebasNeuePro-SemiExpMiddleIt.woff') format('woff'),
    url('../fonts/BebasNeuePro-SemiExpMiddleIt.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Bebas Neue Pro SemiExp';
  src: url('../fonts/BebasNeuePro-SemiExpBoldItalic.eot');
  src: local('Bebas Neue Pro SemiExpanded Bold Italic'), local('BebasNeuePro-SemiExpBoldItalic'),
    url('../fonts/BebasNeuePro-SemiExpBoldItalic.eot?#iefix') format('embedded-opentype'),
    url('../fonts/BebasNeuePro-SemiExpBoldItalic.woff2') format('woff2'),
    url('../fonts/BebasNeuePro-SemiExpBoldItalic.woff') format('woff'),
    url('../fonts/BebasNeuePro-SemiExpBoldItalic.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Inter';

  src: url('../fonts/Inter-Regular.woff2') format('woff2'),
    url('../fonts/Inter-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}


@font-face {
  font-family: 'Inter';
  src: url('../fonts/Inter-Bold.woff2') format('woff2'),
    url('../fonts/Inter-Bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;

}


@font-face {
  font-family: 'Inter';
  src: url('../fonts/Inter-ExtraBold.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src: url('../fonts/Inter-SemiBold.woff2') format('woff2'),
    url('../fonts/Inter-SemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;

}

@font-face {
  font-family: 'Effra';
  src: url('../fonts/Effra-Regular.otf') format('opentype');
  font-weight: 400;
  font-style: normal;

}

@font-face {
  font-family: 'Effra';
  src: url('../fonts/Effra-Italic.otf') format('opentype');
  font-weight: 400;
  font-style: italic;

}

@font-face {
  font-family: 'Effra';
  src: url('../fonts/Effra-Medium.otf') format('opentype');
  font-weight: 500;
  font-style: normal;

}

@font-face {
  font-family: 'Effra';
  src: url('../fonts/Effra-Medium-Italic.otf') format('opentype');
  font-weight: 500;
  font-style: italic;

}

@font-face {
  font-family: 'Effra';
  src: url('../fonts/Effra-Light.otf') format('opentype');
  font-weight: 300;
  font-style: normal;

}

@font-face {
  font-family: 'Effra';
  src: url('../fonts/Effra-Light-Italic.otf') format('opentype');
  font-weight: 300;
  font-style: italic;

}

@font-face {
  font-family: 'Effra';
  src: url('../fonts/Effra-Bold.otf') format('opentype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Effra';
  src: url('../fonts/Effra-Bold-Italic.otf') format('opentype');
  font-weight: bold;
  font-style: italic;

}

@font-face {
  font-family: 'Effra';
  src: url('../fonts/Effra-Heavy.otf') format('opentype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Effra';
  src: url('../fonts/Effra-Heavy-Italic.otf') format('opentype');
  font-weight: 900;
  font-style: italic;

}