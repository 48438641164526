.LoaderWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #FFFFFF;
    z-index: 1000;
    overflow: hidden;
}

.AppSpinner{
    border-radius: 50%;
    width: 100px;
    height: 100px;

    position: relative;
    top:-15px;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;

}
.AppSpinner > img{
    width: 100px;
    height: 100px;
    border-radius: 50%;
}
.Spinner{
    position: absolute;
    left: 0;
    top: 0;
    animation: matrixSpinner infinite linear 3s;
}
@keyframes matrixSpinner {
    0% {
        transform: rotate(0deg) ;
        -ms-transform: rotate(0deg) ;
        -webkit-transform: rotate(0deg) ;
    }

    50% {
        transform: rotate(180deg) ;
        -ms-transform: rotate(180deg) ;
        -webkit-transform: rotate(180deg);
    }

    100% {
        transform: rotate(360deg) ;
        -ms-transform: rotate(360deg) ;
        -webkit-transform: rotate(360deg) ;
    }
}