.Login{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-top: 114px;
}
.Logo{
    width: 163px;
    height: 24px;
}

.Content{
    margin-top: 32px;
    background: linear-gradient(180deg, #BCFECE 0%, #D7FEC2 100%);
    border-radius: 33px;
    max-width: 536px;
    width: 100%;
    padding: 30px 56px 48px ;
    box-sizing: border-box;
    position: relative;
    overflow: hidden;
}

.Title{
    font-family: 'Effra';
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    line-height: 25px;
    text-align: center;
    margin-top: 0;
}

.FootBlock{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 22px;

}

.FootBlock a{
    font-family: 'Effra';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 15px;
    color: #2D8546;
}

.login_button{
  background: #383838;
  border-radius: 6px;
  font-family: 'Bebas Neue Cyrillic';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 92%;
  color: #FFFFFF;
}

.Input{
    margin-bottom: 20px;
}

.login_links_content{
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 32px;
}

.login_text{
  font-size: 12px;
  font-family: 'Effra';
  color: #2D8546;
  margin-bottom: 8px;
}

.recoverButton{
  cursor: pointer;
}