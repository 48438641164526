
.container{
  margin-right: 40px;
}

.input{
  border-color:#808080;
  margin-right: 6px;
}

.input_text {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  color: #000000;
}

input[type="radio"] {
  margin-bottom: -2px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 16px;
  height: 16px;
  padding: 2px;
  background-clip: content-box;
  border: 2px solid #2D8546;
  background-color: #fff;
  border-radius: 50%;
}

input[type="radio"]:checked {
  background-color: #2D8546;
}