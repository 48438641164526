.container{
  min-width: 100%;
  width: 100%;
  padding: 0px;
  min-height: 100%;
}


.input label{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  color: #000000;
  margin-top: 14px;
}

.users_type {
  margin-top: 50px;
}

.radio_label {
  color: green;
}

.left_content {
  padding-right: 8px;
}

.left_content_users_list {
  height: 600px;
  padding-right: 8px;
}



.left_content_users_list::-webkit-scrollbar {
  width: 4px;
}

.left_content_users_list::-webkit-scrollbar-track {
  background-color: #e9e9e9;
}

.left_content_users_list::-webkit-scrollbar-thumb {
  background-color: #979797;
  border-radius: 4px;
}

.right_content{
  padding-left: 15px;
}

.user_type_title, .input_text{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  color: #000000;
}


.form{
  width: 328px;
}

.form .name{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 92%;
  color: #000000;
}

.radio-input:checked+.radio_label::before {
  border-color: green;
  background-color: green;
}

.buttons{
  display: flex;
  margin-top: 50px;
}

.remove_user_button{
  padding: 10px 22px;
  background-color: #fff;
  background: #FFFFFF;
  border: 2px solid #333333;
  border-radius: 6px;
  font-family: 'Bebas Neue Cyrillic';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 92%;
  color: #333333;
  margin-right: 25px;
}

.save_user_button{
  background: #383838;
  border-radius: 6px;
  font-family: 'Bebas Neue Cyrillic';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 92%;
  color: #fff;
  margin-right: 25px;
}

.cancel_user_button{
  padding: 10px 22px;
  background-color: #fff;
  background: #FFFFFF;
  border: 2px solid #333333;
  border-radius: 6px;
  font-family: 'Bebas Neue Cyrillic';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 92%;
  color: #333333;
  margin-right: 25px;
}

.close_button{
  right: 4px;
  top: 4px;
  width: 24px;
  height: 24px;
}

.title{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 32px;
  color: #000000;
}

.create_button_wrapper{
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.selected_create_user_button {
  background: #FFFFFF;
  border: 2px solid #333333;
  border-radius: 6px;
  font-family: 'Bebas Neue Cyrillic';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 92%;
  color: #333333;
  padding: 10px 22px;
  width: fit-content;
  margin-bottom: 18px;
  position: absolute;
  right:0;
  top: -10px;
  z-index: 1;
}

.selected_create_user_button {
  position: relative;
}

.selected_create_user_button img {
  margin-right: 12px;
}

@media (max-width: 991px) {
  .right_content {
    background-color: #fff;
    position: absolute;
    height: 100%;
    width: 100%;
  }
  
  .create_button_wrapper {
    justify-content: center;
  }
}

@media (max-width: 592px) {
  .right_content {
    width: 97% ;
  }
}

@media (max-width: 414px) {
  .right_content {
    width: 96% ;
  }
  .right_content {
    padding-left: 0px;
  }
}