.Notification {
    position: relative;
    color: #fff;
    margin-bottom: 10px;
    opacity: 0;
    transition: opacity .3s, transform .3s, height 0.5s, margin-bottom 0.3s;
    transform: translateY(-100px);
    cursor: pointer;
    -moz-user-select: none;
    -khtml-user-select: none;
    user-select: none;
}


.Notification.Important {
    z-index: 1500;
}

/**Animation**/
.NotificationActive {
    transform: translateY(0px);
    opacity: 1;
    transition: opacity .3s, transform .3s, height 0.5s, margin-bottom 0.3s;
}
.NotificationDone {
    transform: translateY(0px);
    opacity: 1;
}
.NotificationExitActive {
    transform: translateY(0px);
    opacity: 1;
}
.NotificationExitDone {
    transform: translateY(-100px);
    opacity: 0;
    transition: opacity .3s, transform .3s, height 0.5s, margin-bottom 0.3s;
}
/**End Animation**/


.Notification .NotificationContent {
    font-size: 16px;
    padding: 17px 30px;
    width: 100%;
    color:#FFFFFF;
}


@media (max-width: 768px) {
    .Notification {
        width: 100%;
    }
    .Notification .NotificationClose {
        display: none;
    }
}
