.container{
  min-width: 100%;
  padding: 0px;
  position: relative;
  min-height: 64vh;
}

.item_wrapper{
  padding-top: 10px;
  padding-bottom: 12px;
  align-items: center;
  width: 100%;
  border-bottom: 1px solid #D9D9D9;
}