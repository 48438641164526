.container{
  min-width: 100%;
  padding: 0px;
}

.container_bg{
  background: rgba(251,251,251);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
}

.col {
  padding: 0px 2px;
}

.first_section{
  align-items: flex-end;
}

.first_section_content{
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.first_section_content .title{
  margin-bottom: 8px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #000000;
}

.remove_icon{
  cursor: pointer;
}

.hero_section{
  margin-top: 46px ;
  padding: 0px 0px 10px ;
  border-bottom: 1px solid #E4E4E6 ;
}

.hero_section_title{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #000000;
}


.second_section{
  margin-top: 30px;
}

.second_section_title{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #838383;
}

.second_section_content{
  display: flex;
  margin-top: 15px;
  justify-content: space-between;
}

.second_section_content_avatar{
  width: 88px;
  height: 88px;
  border-radius: 50%;
  overflow: hidden;
  object-fit: cover;
}

.file_input_label{
  cursor: pointer;
}

.second_section_content_avatar img{
  width: 88px;
  height: 88px;
  object-fit: cover;
}

.second_section_content_button{
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}

.second_section_content_button_wrapper{
  position: relative;
  overflow: hidden;
  cursor: pointer;
}

.file_input{
  position: absolute;
  top: 10%;
  left: -50%;
  z-index: -10;
  opacity: 0;
  cursor: pointer;
}

.second_section_content_button span{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #838383;
}

.second_section_input_content{
  margin-top: 20px;
}

.second_section_input_content .title{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
}

.save_button_zone{
  width: fit-content;
  position: absolute;
  left: calc(100% - 105px);
}

.second_section_bg{
  padding-left: 30px;
  box-sizing: border-box;
  position: relative;
}

.second_section_bg .title{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #838383;
}

.second_section_bg_content{
  display: flex;
  margin-top: 15px;
}

.second_section_bg_content_img{
  width: 88px;
  height: 88px;
  overflow: hidden;
  margin-right: 32px;
}

.second_section_bg_content_img img{
  width: 88px;
  height: 88px;
  margin-right: 32px;
}

.second_section_bg_content .button{
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.second_section_bg_content .button .title{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #838383;
}

.second_section_bg .input_section{
  margin-top: 20px;
}

.second_section_bg .input_section .title{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
}


.product_section{
  margin-top: 46px;
  padding: 0px 0px 10px;
  border-bottom: 1px solid #E4E4E6;
}

.product_section_title{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #000000;
}

.third_section{
  margin-top: 30px;
}


.content {
  padding: 20px 30px;
  border: 1px solid #E4E4E6;
  border-radius: 8px;
  display: flex;
  align-items: center;
  background: #FFFFFF;
  flex-direction: column;
  margin-bottom: 30px;
}

.content_header{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0px 0px 10px 0px;
  border-bottom: 1px solid #E4E4E6;
  margin-bottom: 24px;
}

.content_header_title{
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  color: #000000;
}

.content .image{
  width: 172px;
  height: 172px;
  background-size: cover;
  background-color: rgba(255,255,255,0.7);
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

.content .inputs{
  width: 100%;
}

.content .inputs .done_button {
  display: flex;
  justify-content: center;
}

.content .input_content{
  width: 100%;
  margin-bottom: 16px;
  position: relative;
}

.content .input_content .title{
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 15px;
  color: #000000;
}

.card_input {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  color: #000000;
  padding: 10px;
  width: 100%;
  background: #FFFFFF;
  border: 1px solid #383838;
  border-radius: 6px;
}

.buttons{
  display: flex;
  align-items: center;
  justify-content: flex-end;
}


.view_button {
  padding: 0px 22px;
  background-color: #fff;
  background: #FFFFFF;
  border: 2px solid #333333;
  border-radius: 6px;
  font-family: 'Bebas Neue Cyrillic';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 92%;
  color: #333333;
  margin-right: 8px;
  margin-left: 8px;
  position: relative;
  overflow: hidden;
  height: 38px;
}


.add_avatar_button {
  padding: 10px 22px;
  background-color: #fff;
  background: #FFFFFF;
  border: 2px solid #333333;
  border-radius: 6px;
  font-family: 'Bebas Neue Cyrillic';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 92%;
  color: #333333;
  margin-bottom: 8px;
}

.lunch_button {
  background: #383838;
  border-radius: 6px;
  font-family: 'Bebas Neue Cyrillic';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 92%;
  color: #fff;
  padding: 0px 22px;
  height: 38px;
}

.lunch_button_bottom{
  display: flex;
  justify-content: center;
}

.create_new_card {
  background: #FFFFFF;
  border: 2px solid #333333;
  border-radius: 6px;
  font-family: 'Bebas Neue Cyrillic';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 92%;
  color: #333333;
  padding: 10px 22px;
  display: flex;
}


.add_content {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 562px !important;
}

.second_section_save_button{
  margin-top: 24px;
  display: flex;
  justify-content: center;
  display: none;
}

.search_component{
  border-radius: 6px;
  border: 1px solid #383838;
  background: #FFF;
  padding: 9px 12px;
  position: absolute;
  width: 100%;
  top: 24px;
  z-index: 1;
}

.search_component_header{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
}

.search_component_header_title{
  font-family: 'Roboto';
  font-size: 13px;
  font-weight: 400;
  line-height: 15px;
  letter-spacing: 0px;
  text-align: left;
  color: #838383;
}

.search_input{
  padding-right: 30px;
}

.search_component_header img{
  cursor: pointer;
}

.status_text{
  font-family: 'Roboto';
  font-size: 13px;
  font-weight: 400;
  line-height: 15px;
  letter-spacing: 0px;
  text-align: left;
  color: #C1C1C1;
}

.search_component_block_item{
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.search_component_block_item:hover{
  background-color: #F1F1F1;
}

@media (max-width: 1024px) {
  .container {
    padding: 0px 8px;
  }

  .second_section_save_button {
    display: flex;
  }

  .save_button_zone{
    display: none;
  }
}

@media (max-width: 767px) {
 .buttons{
  display: flex;
  justify-content: center;
  margin-top: 10px;
 }
 .second_section_bg {
   padding-left: 0px;
   box-sizing: border-box;
   margin-top: 42px;
 }
 .button_text{
  display: none;
 }
 .bottom_buttons .view_button{
  padding: 0px 14px;
  margin-right: 4px;
  margin-left: 4px;
 }
 .bottom_buttons img{
  margin-right: 0px;
 }
 .add_content {
   min-height: fit-content !important;
 }
 .bottom_buttons {
   display: flex;
   justify-content: center;
 }
}