.container{
  display: flex;
  justify-content: center;
}

.add_landing_button{
  background: #FFFFFF;
  border: 2px solid #333333;
  border-radius: 6px;
  font-family: 'Bebas Neue Cyrillic';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 92%;
  color: #333333;
  padding: 10px 22px;
  width: fit-content;
}

