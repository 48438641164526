.Btn{
  padding: 10px 22px;
  border-radius: 6px;
  text-transform: uppercase;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Btn img{
  margin-right: 12px;
  margin-top: -2px;
}


.disabled_button{
  opacity: 0.3;
  cursor: unset !important;
  pointer-events: none;
}

.Btn:hover{

}
.Login{

}