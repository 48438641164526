.notificationContent{
    border-radius: 12px;
    filter: drop-shadow(0px 16px 20px rgba(0, 0, 0, 0.12));
    padding: 24px;
    width: 396px;
    min-height: 129px;
    box-sizing: border-box;
}

.notificationContent.primary {
    background:#2D8546;
}

.notificationContent.error {
    background: #D13B3B;
}


.notificationContent.message {
    background: #2D8546;
}

.notificationContent.message .content{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 32px;
    color: #333333;
    padding-right: 30px;
}

.content{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    color: #FFFFFF;
    padding: 0px 20px;
    padding-top: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 44px;
}
.notificationClose {
    width: 14px;
    height: 14px;
    cursor: pointer;
    color: #ffffff;
    font-size: 20px;
}

.close{
  display: flex;
  justify-content: flex-end;
}