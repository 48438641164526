.container{
  min-width: 100%;
  padding: 0px;
  position: relative;
  margin-bottom: 26px;
}

.col{
  padding: 0px 2px;
}
.col div{
  width: 100%;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-size: 100% 32px;
  background-repeat: no-repeat;
}

.status_text{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 32px;
  color: #2D8546;
}

@media (max-width: 1024px) {
  .container {
    padding-left: 8px;
    padding-right: 8px;
  }
}


@media (max-width: 639px) {
  .col div {
    font-size: 10px;
  }
}

@media (max-width: 414px) {
  .col div {
    font-size: 8px;
  }
}