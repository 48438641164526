.container{

}

.content:hover{
  border: 1px solid #86D790;
}

.avatar{
  margin-right: 15px;
}

.content{
  padding: 20px 16px;
  border: 1px solid #E4E4E6;
  margin: 16px 0px;
  display: flex;
  align-items: center;
  border-radius: 8px;
  background: #FFFFFF;
  cursor: pointer;
}

.active_content{
  border: 1px solid #86D790;
}

.text{
  display: flex;
  flex-direction: column;
}

.name_text {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 92%;
  color: #000000;
  margin: 6px 0px;
  display: block;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}

.admin_text{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 92%;
  color: #B23333;
  margin: 6px 0px;
}

.moderator_text{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 92%;
  color: #2D8546;
  margin: 6px 0px;
}