.container {
  display: flex;
  border-bottom: 1px solid #D9D9D9;
  height: 24px;
  width: fit-content;
  display: flex;
  justify-content: space-between;
  margin-bottom: 25px;
}

.item {
  font-family: 'Bebas Neue Cyrillic';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 92%;
  color: #CCCCCC;
  cursor: pointer;
  padding: 0px 15px;
}

.item:last-child {
  font-family: 'Bebas Neue Cyrillic';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 92%;
  margin-right: 0px;
  cursor: pointer;
}

.active_item {
  color: #2D8546;
  border-bottom: 1px solid #2D8546;
}

.container_open {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.container_open .item {
  margin-right: 0px;
}


@media (max-width: 730px) {
 .container{
  display: flex;
  border-bottom: 1px solid #D9D9D9;
  height: 24px;
  width: -moz-fit-content;
  width: fit-content;
  display: flex;
  justify-content: flex-start;
  margin-bottom: 70px;
  flex-wrap: wrap;
  border-bottom: none;
 }

 .item{
  margin: 8px 0px;
  font-size: 18px;
 }
}