.container{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 166px;
}

.text{
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: #838383;
}

.empty_icon{
  margin-top: 26px;
  margin-bottom: 120px;
}